<template>
	<div class="gg-container">
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>search01：</label>
				<el-input
					size="small"
					placeholder="search01"
					prefix-icon="el-icon-search"
					v-model="searchParams.search"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>
			
			<div class="search-container-fn-input">
				<label>select01：</label>
				<el-select
					v-model="searchParams.select"
					placeholder="select01"
					size="small"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			
			<div class="search-container-fn-input">
				<label>dateTime01：</label>
				<el-date-picker
					v-model="searchParams.timeValue"
					type="daterange"
					size="small"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="getList('restPage')">
				</el-date-picker>
			</div>
			
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlebtn01()">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">弹出框</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handlebtn01(scope.$index, scope.row)">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px">弹出框</span>
					</el-button>
				</div>
			</div>
		</div>
		
		<dialog-test :isActiveDialog.sync="isActiveDialog"
								 @getList="getList"
								 v-if="isActiveDialog">
		
		</dialog-test>
	</div>
</template>

<script>

import dialogTest from './components/dialogTest';

export default {
  name: "Test",
  components: {
    dialogTest
  },
  created () {
  },
  data () {
    return {
      searchParams:{},
      isActiveDialog: false,
      options: [],
    }
  },
  methods: {
    getList() {
      console.log('Test.getList')
    },
    handlebtn01() {
      this.isActiveDialog = true
    },
    handleSelect(){

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
